import React from 'react'
import Select from 'react-select'


const options = [
    {value: 'm', label: 'Male'},
    {value: 'f', label: 'Female'}
];
const valueFromId = (opts, id) => opts.find(o => o.value === id);


export default class SexPicker extends React.Component {
  constructor(props) {
    super(props);
    
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(option) {
    this.props.onChange(option.value);
  }

  render() {
    const value = valueFromId(options, this.props.value);
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        value={value}
      />
    );
  }
}
