import _ from 'lodash'
import React from 'react'
import { addDays, format, getDaysInYear, getYear } from 'date-fns'
import { Link } from 'gatsby'
import amplitude from 'amplitude-js'

import data from '../../data/processed_who_data.json'
import BirthYearPicker from '../components/birth_year_picker'
import CountryPicker from '../components/country_picker'
import MainLayout from "../components/main_layout"
import SampleViz from '../components/sample_viz'
import SexPicker from '../components/sex_picker'

import styles from './index.module.css'

const numSamples = 40000;
const numSamplesToShow = 1000;


function bernoulliDraw(p) {
  return Math.random() < p;
}


function getIndexForAge(table, age) {
  let index = 0;
  while (table[index].s < age) {
    index++;
  }
  return index - 1;
}


function singleDraw(table, currentYear, birthYear) {
  let deathYear = currentYear;
  let index = getIndexForAge(table, deathYear - birthYear);
  let isDead = false;
  while (!isDead) {
    deathYear++;
    let currentAge = deathYear - birthYear;
    if (currentAge > table[index].e) {
      index++;
    }
    let deathProb;
    if (index !== table.length - 1) {
      let progressInGroup = (currentAge - table[index].s) / (table[index+1].s - table[index].s);

      deathProb = (1 - progressInGroup) * table[index].r + progressInGroup * table[index+1].r;
    } else {
      deathProb = table[index].r;
    }
    isDead = bernoulliDraw(deathProb);
  }
  return deathYear;
}


function computeDistribution(birthYear, sex, country) {
  const table = data[country][sex];
  const currentYear = getYear(new Date());
  let samplesToShow = new Array(numSamplesToShow);
  let pdf = new Array(149).fill(0);  // Much longer than anyone will live
  for (let i = 0; i < numSamples; i++) {
    const draw = singleDraw(table, currentYear, birthYear); ;
    if (i < numSamplesToShow) {
      samplesToShow[i] = draw + Math.random();
    }
    pdf[draw - currentYear - 1]++;
  }
  let cdf = new Array(149).fill(0);
  for (let i = 0; i < pdf.length; i++) {
    pdf[i] = pdf[i] / numSamples;
    if (i === 0) {
      cdf[i] = pdf[i];
    } else {
      cdf[i] = cdf[i-1] + pdf[i];
    }
  }
  samplesToShow.sort((a, b) => (a - b));
  return [samplesToShow, pdf, cdf];
}


export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.computeDeathDayInfo = this.computeDeathDayInfo.bind(this);
    this.handleBirthYearChange = this.handleBirthYearChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleSexChange = this.handleSexChange.bind(this);
    this.logGetFirstResultsIfNeeded = this.logGetFirstResultsIfNeeded.bind(this);
    this.newSample = this.newSample.bind(this);

    this.state = {
      birthYear: null,
      sex: null,
      country: null,
      sampleIndex: _.random(0, numSamplesToShow),
      showingResults: false
    };
  }

  componentDidMount() {
    amplitude.getInstance().init('b743ec06d753e6f4767b6993243c7b14');
    amplitude.getInstance().logEvent('loadIndexPage');
  }

  handleBirthYearChange(newBirthYear) {
    amplitude.getInstance().logEvent('changeBirthYear', {
      oldValue: this.state.birthYear,
      newValue: newBirthYear
    });
    if (this.state.sex && this.state.country) {
      this.logGetFirstResultsIfNeeded(newBirthYear, this.state.sex, this.state.country);
      const [samples, pdf, cdf] = computeDistribution(newBirthYear, this.state.sex, this.state.country);
      this.setState({
        birthYear: newBirthYear,
        samples: samples,
        pdf: pdf,
        cdf: cdf,
        showingResults: true
      });
    } else {
      this.setState({
        birthYear: newBirthYear
      })
    }
  }

  handleCountryChange(newCountry) {
    amplitude.getInstance().logEvent('changeCountry', {
      oldValue: this.state.country,
      newValue: newCountry
    });
    if (this.state.birthYear && this.state.sex) {
      this.logGetFirstResultsIfNeeded(this.state.birthYear, this.state.sex, newCountry);
      const [samples, pdf, cdf] = computeDistribution(this.state.birthYear, this.state.sex, newCountry);
      this.setState({
        country: newCountry,
        samples: samples,
        pdf: pdf,
        cdf: cdf,
        showingResults: true
      });
    } else {
      this.setState({
        country: newCountry
      })
    }
  }

  handleSexChange(newSex) {
    amplitude.getInstance().logEvent('changeSex', {
      oldValue: this.state.sex,
      newValue: newSex
    });
    if (this.state.birthYear && this.state.country) {
      this.logGetFirstResultsIfNeeded(this.state.birthYear, newSex, this.state.country);
      const [samples, pdf, cdf] = computeDistribution(this.state.birthYear, newSex, this.state.country);
      this.setState({
        sex: newSex,
        samples: samples,
        pdf: pdf,
        cdf: cdf,
        showingResults: true
      });
    } else {
      this.setState({
        sex: newSex
      })
    }
  }

  newSample() {
    amplitude.getInstance().logEvent('getNewSample', {
      birthYear: this.state.birthYear,
      country: this.state.country,
      sex: this.state.sex
    });
    this.setState({
      sampleIndex: _.random(0, numSamplesToShow),
    })
  }

  computeDeathDayInfo() {
    let deathYearFloat = this.state.samples[this.state.sampleIndex];
    let deathYear = Math.floor(deathYearFloat);
    let deathYearFrac = deathYearFloat - deathYear;
    let numDaysInYear = getDaysInYear(new Date(deathYear, 1, 1));
    let dayOfYear = Math.floor(deathYearFrac * numDaysInYear);
    let deathDate = addDays(new Date(deathYear, 1, 1), dayOfYear);
    let deathDateStr = format(deathDate, 'MMMM d, yyyy');
    let currentYear = getYear(new Date());
    let percent = Math.round(100 * this.state.cdf[deathYear - currentYear - 1]);

    return {
      age: deathYear - this.state.birthYear,
      deathDateStr: deathDateStr,
      percent: percent,
    }
  }

  logGetFirstResultsIfNeeded(birthYear, sex, country) {
    if (this.state.showingResults) {
      return;
    }
    amplitude.getInstance().logEvent('getFirstResults', {
      birthYear: birthYear,
      country: country,
      sex: sex
    });
  }

  render() {
    let deathDayInfo;
    if (this.state.showingResults) {
      deathDayInfo = this.computeDeathDayInfo();
    }
    return (
      <MainLayout
        birthYear={this.state.birthYear}
        country={this.state.country}
        sex={this.state.sex}
      >
        <div className={styles.mainQuoteContainer}>
          <p className={styles.mainQuote}>
            “Since death alone is certain, and the time of death uncertain, what should I do?”
          </p>
          <p className={styles.mainQuoteAuthor}>
            - Stephen Batchelor
          </p>
          <p className={styles.mainQuoteBook}>
            Buddhism Without Beliefs
          </p>
        </div>
        <div className={styles.skipLink}>
          <a
            href='#viz-heading'
            onClick={() => {amplitude.getInstance().logEvent('clickVizShortcut');}}
          >
            Visualize when you might die
          </a>
        </div>
        <h3 className={styles.mainHeader}>
          Life expectancy is just an average
        </h3>
        <p className={styles.paragraph}>
          You may already have an idea of what your life expectancy is, but it’s easy to forget that it’s just an average of a highly uncertain set of possibilities.
        </p>
        <p className={styles.paragraph}>
          Consider the following example. If you are a 30-year-old male in the United States, your life expectancy is 77. That means you have a 50% chance of dying on or before your 77th birthday. That’s the chance of flipping a coin and getting heads.
        </p>
        <p className={styles.paragraph}>
          But what if you flip a coin twice and get two heads? There’s a 25% chance of that, which is the chance you’ll die on or before your 66th birthday. Get three heads in a row (13% chance), and you’ve matched the probability that you’ll die before you turn 57. Four in a row (6% chance), and you’ve matched the probability that you’ll die before you turn 49.
        </p>
        <h3 className={styles.mainHeader}>
          Can remembering this uncertainty motivate us to live better?
        </h3>
        <p className={styles.paragraph}>
          Most of us act as if we’ll live out our full life expectancy. But we can die long before then &mdash; it’s not even that unlikely.
        </p>
        <p className={styles.paragraph}>
          The purpose of this website is to help you internalize how uncertain it is when you will die.
        </p>
        <p className={styles.paragraph}>
          This isn’t a gratuitous exercise in morbidity. It’s a way to help you remember that life is short and uncertain. The more we can remember that, the better we can live.
        </p>
        <div className={styles.interactiveContainer}>
          <h3 className={styles.interactiveTitle}>
            Visualize when you might die
          </h3>
          <p className={styles.paragraph} id='viz-heading'>
            Enter some basic information about yourself to visualize the range of possibilities for when you’ll die.
          </p>
          <div className={styles.controls}>
            <div className={styles.controlLabel}>
              Year of birth
            </div>
            <div className={styles.control}>
              <BirthYearPicker
                onChange={this.handleBirthYearChange}
                value={this.state.birthYear}
              />
            </div>
            <div className={styles.controlLabel}>
              Sex
            </div>
            <div className={styles.control}>
              <SexPicker
                onChange={this.handleSexChange}
                value={this.state.sex}
              />
            </div>
            <div className={styles.controlLabel}>
              Country of residence
            </div>
            <div className={styles.control}>
              <CountryPicker
                onChange={this.handleCountryChange}
                value={this.state.country}
              />
            </div>
          </div>
          { this.state.showingResults
            ? (
                <>
                  <div className={styles.results}>
                    <div className={styles.deathFact}>
                      {'You might die on ' + deathDayInfo.deathDateStr + ', at age ' + deathDayInfo.age}.
                    </div>
                    <div className={styles.deathFact}>
                      {'You have a ' + deathDayInfo.percent + '% chance of dying on or before this day.'}
                    </div>
                    <div className={styles.vizContainer}>
                      <SampleViz
                        birthYear={this.state.birthYear}
                        cdf={this.state.cdf}
                        sampleIndex={this.state.sampleIndex}
                        samples={this.state.samples}
                      />
                      <div className={styles.caption}>
                        {numSamplesToShow} randomly sampled dates of death
                      </div>
                    </div>
                  </div>
                  <div className={styles.methodologyLink}>
                    <Link to='/about/'>Methodology</Link>
                  </div>
                  <div className={styles.mainButtonContainer}>
                    <button
                      disabled={!(this.state.birthYear && this.state.country && this.state.sex)}
                      onClick={this.newSample}
                    >
                      Get new date
                    </button>
                  </div>
                </>
              )
            : null
          }
        </div>
      </MainLayout>
    );
  }
}
