import React from 'react'
import _ from 'lodash'
import * as d3 from 'd3'
import { getYear } from 'date-fns'

import styles from './sample_viz.module.css'


const numYearsToDisplay = 110;


function getRandomJitterOffsets(n) {
  let result = new Array(n);
  for (let i = 0; i < n; i++) {
    result[i] = Math.random() - .5;
  }
  return result;
}


export default class SampleViz extends React.Component{
  constructor(props) {
    super(props);
    this.updateD3 = this.updateD3.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);

    this.state = {
      randomJitterOffsets: getRandomJitterOffsets(props.samples.length)
    };
  }

  componentDidMount() {
    this.updateD3();
  }

  componentDidUpdate() {
    this.updateD3();
  }

  updateD3() {
    const n = this.props.samples.length;

    const vizContainer = document.getElementById('inner-sample-viz-container');
    const width = vizContainer.offsetWidth;
    const height = vizContainer.offsetHeight;

    const currentYear = getYear(new Date());

    let x = d3.scaleLinear()
      .domain([currentYear, currentYear + numYearsToDisplay])
      .range([0, width]);

    let y = d3.scaleLinear()
      .domain([-12, 4])
      .range([0, height]);

    const data = _.range(n);

    d3.select('#inner-sample-viz-container').select('svg')
      .call(
        d3.axisBottom(x)
          .tickFormat(d3.format(''))  // Make sure we see 2030 instead of 2,030
      );

    d3.select('#inner-sample-viz-container').select('svg')
      .selectAll('circle')
      .data(data)
        .transition()
        .duration(500)
        .ease(d3.easeCubic)
        .attr('cx', (d) => x(this.props.samples[d]))
        .attr('cy', (d) => y(this.state.randomJitterOffsets[d]))
        .attr('r', (d) => (d === this.props.sampleIndex ? 12 : 4))
        .style('fill', (d) => (d === this.props.sampleIndex ? '#FC1A43' : '#000000'))
        .style('fill-opacity', (d) => (d === this.props.sampleIndex ? '.9' : '.025'));

    d3.select('#inner-sample-viz-container').select('svg')
      .selectAll('circle')
      .data(data)
        .enter().append('circle')
          .attr('cx', (d) => x(this.props.samples[d]))
          .attr('cy', (d) => y(this.state.randomJitterOffsets[d]))
          .attr('r', (d) => (d === this.props.sampleIndex ? 12 : 4))
          .style('fill', (d) => (d === this.props.sampleIndex ? '#FC1A43' : '#000000'))
          .style('fill-opacity', (d) => (d === this.props.sampleIndex ? '.9' : '.025'))
        .exit().remove();
  }

  handleMouseOver(e) {
    const vizContainer = document.getElementById('inner-sample-viz-container');
    const height = vizContainer.offsetHeight;
    const width = vizContainer.offsetWidth;
    const rect = vizContainer.getBoundingClientRect();

    const eventX = e.clientX - rect.left + 1.5;
    const eventY = e.clientY - rect.top;

    let pixelToCdfInput = d3.scaleLinear()
      .domain([0, width])
      .range([0, numYearsToDisplay]);

    d3.select('#inner-sample-viz-container').select('svg')
      .selectAll('#percentile-line')
      .data([[eventX, eventY]])
        .attr('id', 'percentile-line')
        .attr('x1', (d) => d[0])
        .attr('x2', (d) => d[0])
        .attr('y1', (d) => 0)
        .attr('y2', (d) => height)
        .style('stroke', (d) => '#000000')
        .style('stroke-width', (d) => '1');

    d3.select('#inner-sample-viz-container').select('svg')
      .selectAll('#percentile-line')
      .data([[eventX, eventY]])
        .enter().append('line')
        .attr('id', 'percentile-line')
        .attr('x1', (d) => d[0])
        .attr('x2', (d) => d[0])
        .attr('y1', (d) => 0)
        .attr('y2', (d) => height)
        .style('stroke', (d) => '#000000')
        .style('stroke-width', (d) => '1');

    let label = document.getElementById('sample-viz-percentile-label');
    let cdfInput = Math.round(pixelToCdfInput(eventX)) - 1;

    if (cdfInput < 0) {
      label.style.display = 'none';
      console.log('here');
      return;
    }

    const percentile = this.props.cdf[cdfInput];
    const currentYear = getYear(new Date());
    const age = cdfInput + currentYear - this.props.birthYear + 1;

    label.style.display = 'inline-block';
    label.style.left = eventX.toString() + 'px';
    label.innerHTML = '' + Math.round(percentile * 1000) / 10 + '% chance of dying before age ' + age;
  }

  handleMouseOut(event) {
    d3.select('#inner-sample-viz-container').select('svg')
      .selectAll('#percentile-line')
      .data([]).exit().remove();

    let label = document.getElementById('sample-viz-percentile-label');
    label.style.display = 'none';
  }

  render() {
    return (
      <div
        className={styles.outerContainer}
        id='outer-sample-viz-container'
        onMouseMove={this.handleMouseOver}
        onMouseLeave={this.handleMouseOut}
      >
        <div
          id='sample-viz-percentile-label'
          className={styles.percentileLabel}
        />
        <div
          id='inner-sample-viz-container'
          className={styles.innerContainer}
        >
          <svg />
        </div>
      </div>
    )
  }
}
